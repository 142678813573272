var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item != null)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-alert',{staticClass:"pb-2",attrs:{"value":_vm.formError,"color":"error"}},[_vm._v(_vm._s(_vm.formError))]),_c('v-text-field',{staticClass:"mb-4",attrs:{"label":_vm.$t('environmentName'),"rules":[function (v) { return !!v || _vm.$t('name_required'); }],"required":"","disabled":_vm.formSaving},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_c('v-subheader',{staticClass:"px-0"},[_vm._v(" "+_vm._s(_vm.$t('extraVariables'))+" "),_c('v-tooltip',{attrs:{"bottom":"","color":"black","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1"},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,3762324429)},[_c('span',[_vm._v("Variables passed via "),_c('code',[_vm._v("--extra-vars")]),_vm._v(".")])]),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"tile":"","group":""},model:{value:(_vm.extraVarsEditMode),callback:function ($$v) {_vm.extraVarsEditMode=$$v},expression:"extraVarsEditMode"}},[_c('v-btn',{staticClass:"mr-0",staticStyle:{"border-radius":"4px"},attrs:{"value":"json","small":"","disabled":""}},[_vm._v(" JSON ")])],1)],1),_c('codemirror',{style:({ border: '1px solid lightgray' }),attrs:{"options":_vm.cmOptions,"placeholder":_vm.$t('enterExtraVariablesJson')},model:{value:(_vm.json),callback:function ($$v) {_vm.json=$$v},expression:"json"}}),_c('div',[_c('v-subheader',{staticClass:"px-0 mt-4"},[_vm._v(" "+_vm._s(_vm.$t('environmentVariables'))+" "),_c('v-tooltip',{attrs:{"bottom":"","color":"black","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"lightgray"}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,1410459625)},[_c('span',[_vm._v("Variables passed as process environment variables.")])])],1),_c('v-chip-group',{staticClass:"EnvironmentForm__predefinedEnvVars",attrs:{"column":"","multiple":""},model:{value:(_vm.predefinedEnvVars),callback:function ($$v) {_vm.predefinedEnvVars=$$v},expression:"predefinedEnvVars"}},_vm._l((_vm.PREDEFINED_ENV_VARS),function(item){return _c('v-chip',{key:item.name,attrs:{"filter":"","outlined":""}},[_c('span',{staticClass:"EnvironmentForm__predefinedEnvVarsValue"},[_vm._v(" "+_vm._s(item.name)+"="+_vm._s(item.value)+" ")]),_c('v-tooltip',{attrs:{"bottom":"","color":"black","max-width":400,"open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",staticStyle:{"margin-right":"-6px"}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])],1)}),1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.env,"items-per-page":-1,"hide-default-footer":"","no-data-text":"No values"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"pa-1"},[_c('v-text-field',{staticClass:"v-text-field--solo--no-min-height",attrs:{"solo-inverted":"","flat":"","hide-details":""},model:{value:(props.item.name),callback:function ($$v) {_vm.$set(props.item, "name", $$v)},expression:"props.item.name"}})],1),_c('td',{staticClass:"pa-1"},[_c('v-text-field',{staticClass:"v-text-field--solo--no-min-height",attrs:{"solo-inverted":"","flat":"","hide-details":""},model:{value:(props.item.value),callback:function ($$v) {_vm.$set(props.item, "value", $$v)},expression:"props.item.value"}})],1),_c('td',{staticStyle:{"width":"38px"}},[_c('v-icon',{staticClass:"pa-1",attrs:{"small":""},on:{"click":function($event){return _vm.removeEnvVar(props.item)}}},[_vm._v(" mdi-delete ")])],1)])]}}],null,false,2124406958)}),_c('div',{staticClass:"text-right mt-2 mb-4"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addEnvVar()}}},[_vm._v("New Variable")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }